import React, { useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import pic2 from '../assets/Careers/pic-2.jpg';
import pic3 from '../assets/Careers/pic-3.jpg';
import pic4 from '../assets/Careers/pic-17.jpg';
import pic5 from '../assets/Careers/pic-5.jpg';
import pic7 from '../assets/Careers/pic-7.jpg';
import pic9 from '../assets/Careers/pic-9.jpg';
import pic10 from '../assets/Careers/pic-10.jpg';
import pic11 from '../assets/Careers/pic-11.jpg';
import pic12 from '../assets/Careers/pic-12.jpg';
import pic13 from '../assets/Careers/pic-16.jpg';
import pic14 from '../assets/Careers/pic-14.jpg';
import pic15 from '../assets/Careers/pic-15.jpg';
import '../styles.css';

const CareersHomePage = () => {
  const images = [
    pic2, pic3, pic4, pic5, pic7, pic9, pic10, pic11, pic12, pic13, pic14, pic15,
  ];

  const carouselRef = useRef(null);

  // Function to handle infinite scrolling
  const scrollCarousel = (offset) => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: offset,
        behavior: 'smooth',
      });
    }
  };

  // Reset scroll to the start when reaching the end of the duplicate images
  useEffect(() => {
    const carousel = carouselRef.current;
    const handleScroll = () => {
      if (carousel.scrollLeft >= carousel.scrollWidth / 2) {
        carousel.scrollLeft = 0; // Reset to the start
      }
    };

    carousel.addEventListener('scroll', handleScroll);
    return () => carousel.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="careers-carousel-wrapper">
      <button className="careers-carousel-control prev" onClick={() => scrollCarousel(-300)}>
        &#60;
      </button>
      <div className="careers-image-carousel-container" ref={carouselRef}>
        <div className="careers-carousel-image-group">
          {[...images, ...images].map((image, index) => (
            <img
              key={index}
              className="careers-carousel-image"
              src={image}
              alt={`Gallery Image ${index + 1}`}
            />
          ))}
        </div>
      </div>
      <button className="careers-carousel-control next" onClick={() => scrollCarousel(300)}>
        &#62;
      </button>
    </div>
  );
};

export default CareersHomePage;
