import React, { useEffect } from 'react';
import '../styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedinIn, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  useEffect(() => {
    document.getElementById("copyright-year").textContent = new Date().getFullYear();
  }, []);

  return (
    <div className='text-center footer text-white'>
      <div>
        <h5>FABER, COE & GREGG, INC.</h5>
        <p>
          550 Meadowlands Pkwy, Secaucus, NJ 07094, United States<br />
          Phone: 201-330-1515 <br />
          Fax: 201-330-9560 <br />
          Toll Free: 844-377-0056
        </p>
      </div>

      <div className='footer-links'>
        <a className="text-decoration-none text-white" href="/aboutUs">About Us</a>
        <span className="mx-2">|</span>
        <a className="text-decoration-none text-white" href="/values">Values</a>
        <span className="mx-2">|</span>
        <a className="text-decoration-none text-white" href="/gallery">Gallery</a>
        <span className="mx-2">|</span>
        <a className="text-decoration-none text-white" href="/partners">Partners</a>
        <span className="mx-2">|</span>
        <a className="text-decoration-none text-white" href="/careers">Careers</a>
        <span className="mx-2">|</span>
        <a className="text-decoration-none text-white" href="/contactUs">Contact Us</a>
      </div>

      {/* Social media icons with colored backgrounds but no links */}
      <ul className="team-icon">
        <li className="twitter">
          <FontAwesomeIcon icon={faTwitter} />
        </li>
        <li className="instagram">
          <FontAwesomeIcon icon={faInstagram} />
        </li>
        <li className="facebook">
          <FontAwesomeIcon icon={faFacebook} />
        </li>
        <li className="linkedIn">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </li>
        <li className="Youtube">
          <FontAwesomeIcon icon={faYoutube} />
        </li>
      </ul>

      <div className="copyrightfooter">
        <p>&copy; <span id="copyright-year"></span> FABER, COE & GREGG, INC. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
