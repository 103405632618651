import React from 'react';
import '../styles.css'; // Adjust the path as needed

// Import images (replace paths with actual file locations)
import bannerImage from '../assets/Values/ValuePic.jpg';
import firstImpressionImage from '../assets/Values/SmilingFace.JPG';
import askQuestionsImage from '../assets/Values/AskQuestions.jpg';
import productKnowledgeImage from '../assets/Values/ProductKnowledge.jpg';
import extrasImage from '../assets/Values/Extras.jpg';
import efficiencyImage from '../assets/Values/RegisterEfficiency.jpg';
import customerNeedsImage from '../assets/Values/Customer-Insights.jpg';
import exceedExpectationsImage from '../assets/Values/Service.jpg';
import alwaysImprovingImage from '../assets/Values/Improve.avif';
import merchandiseSelectionImage from '../assets/Values/Merchandise.jpg';
import speedOfServiceImage from '../assets/Values/Credit-card.jpg';

function Values() {
  return (
    <div>
    <div className="values-page">
      {/* Banner Section */}
      <div className="values-banner" style={{ backgroundImage: `url(${bannerImage})` }}>
        <h1 className="values-title">OUR CORE VALUES</h1>
        <h1 className="values-customer-focus">CUSTOMERS FIRST</h1>
      </div>
      
      {/* Core Values Grid */}
      <div className="core-values-grid">
        <div className="core-value">
          <img src={firstImpressionImage} alt="First Impression" />
          <h3>FIRST IMPRESSION</h3>
          <p>Smile and Groom</p>
        </div>
        <div className="core-value">
          <img src={askQuestionsImage} alt="Ask Questions" />
          <h3>ASK QUESTIONS & ACQUIRE INFORMATION</h3>
          <p>How are you?</p>
        </div>
        <div className="core-value">
          <img src={productKnowledgeImage} alt="Product Knowledge" />
          <h3 style={{color: "darkslategray"}}>BENEFIT OF PRODUCT KNOWLEDGE</h3>
          <p style={{color: "darkslategray"}}>Show them you Know</p>
        </div>
        <div className="core-value">
          <img src={extrasImage} alt="Extras" />
          <h3 style={{color: "darkslategray"}}>EXTRAS</h3>
          <p style={{color: "darkslategray"}}>Selling Suggestions</p>
        </div>
        <div className="core-value">
          <img src={efficiencyImage} alt="Efficiency" />
          <h3 style={{color: "darkslategray"}}>EFFICIENCY</h3>
          <p style={{color: "darkslategray"}}>Speed and Accuracy</p>
        </div>
        <div className="core-value">
          <img src={customerNeedsImage} alt="Customer Needs" />
          <h3 style={{color: "darkslategray"}}>UNDERSTANDING CUSTOMER NEEDS</h3>
          <p style={{color: "darkslategray"}}>Gathering Customer Insight</p>
        </div>
      </div>
    </div>
   <div className="service-standards-container">
    <div className="service-standards">
    <h2 className="standards-title">SERVICE STANDARDS</h2>

    {/* Standards Section */}
    <div className="standards-grid">
      <div className="standard-item">
        <img src={exceedExpectationsImage} alt="Exceed Customer Expectations" />
        <div>
          <h3>Exceed Customer Expectations Everyday</h3>
          <p>Improving customer experiences daily is key to exceptional service. By actively listening to feedback and refining services, we exceed expectations and foster a culture of adaptability.</p>
        </div>
      </div>
      <div className="standard-item">
        <img src={alwaysImprovingImage} alt="Always Improving" />
        <div>
          <h3>Always Improving</h3>
          <p>Commitment to growth and excellence drives innovation and customer satisfaction. Embracing feedback and adapting to trends are essential for continuous improvement.</p>
        </div>
      </div>
      <div className="standard-item">
        <img src={merchandiseSelectionImage} alt="Merchandise Selection" />
        <div>
          <h3>Merchandise Selection Is Important</h3>
          <p>A well-curated merchandise selection reflects company values and impacts customer loyalty. Aligning with trends and quality expectations enhances satisfaction and sales.</p>
        </div>
      </div>
      <div className="standard-item">
        <img src={speedOfServiceImage} alt="Speed Of Service Standard" />
        <div>
          <h3>Speed Of Service Standard</h3>
          <p>Fast transactions are crucial. We aim for transactions under 3 seconds and accept various payment methods for quick and efficient service.</p>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  );
}

export default Values;
