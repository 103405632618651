import React, { useState, useRef } from 'react';
import Communication from '../assets/Gallery/Missoula-Pic-9.jpg';
import '../styles.css';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';

function ContactUs() {
  const [selectedOption, setSelectedOption] = useState(null);
  const formRef = useRef(null); // To reference the form for scrolling
  const [formData, setFormData] = useState({
    companyName: '',
    contactPerson: '',
    position: '',
    streetAddress: '',
    city: '',
    state: '',
    email: '',
    phoneNumber: '',
    enquiry: '',
  });
  const [feedbackType, setFeedbackType] = useState('');
  const [rating, setRating] = useState(0);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    // Scroll to the form section after option selection
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const isFormValid = Object.values(formData).every(field => field.trim() !== '');
    if (!isFormValid) {
      setShowError(true);
      setShowSuccess(false);
    } else {
      setShowError(false);
      setShowSuccess(true);
  
      // Determine whether it is Feedback or Business Inquiry
      const endpoint = selectedOption === "Feedback" ? 'http://localhost:5000/submit-feedback' : 'http://localhost:5000/submit-inquiry';
  
      // Send form data to the correct endpoint (either for feedback or business inquiry)
      fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
      .then((response) => response.json())
      .then((data) => {
        console.log('Form submitted successfully:', data);
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
    }
  };
  

  const handleRatingChange = (event) => {
    setRating(parseInt(event.target.value));
  };

  return (
    <Container className="contact-container">
      <div>
      <Row className="mb-4">
        <Col>
        <div className='ContactUsImageContaineri'>
        <img src={Communication} alt="Image" className="ContactUsImagei"/>
        <div className="text-overlayi">
        <p  style={{ fontSize: 70,color: 'var(--yellow)'}}>CONTACT US</p>
      </div>
        </div>
          
        </Col>
      </Row>
      </div>
      <Row className="mb-4 text-center">
        <Col>
          <h2 className="contact-heading">We'd Love to Hear from You</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <div className="enquiry-options">
          <Col md={4}>
            <div className="enquiry-option" onClick={() => handleOptionChange("Feedback")}>
              <h2>Customer Feedback</h2>
              <p>Your feedback helps us improve. Let us know your thoughts!</p>
              <Button variant="primary">Provide Feedback</Button>
            </div>
          </Col>
          <Col md={4}>
            <div className="enquiry-option" onClick={() => handleOptionChange("Business")}>
              <h2>Business Inquiry</h2>
              <p>Interested in partnering with us? We'd love to hear from you!</p>
              <Button variant="primary">Inquire About Business</Button>
            </div>
          </Col>
        </div>
      </Row>
      {selectedOption && (
        <Row className="mb-4">
          <Col ref={formRef}>
            <div className="enquiry-form">
              {showError && (
                <Alert variant="danger">
                  Please complete all required fields.
                </Alert>
              )}
              {showSuccess && (
                <Alert variant="success">
                  Form submitted successfully!
                </Alert>
              )}

              {selectedOption === "Feedback" && (
                <>
                  <h2>Customer Feedback</h2>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="date">
                      <Form.Label>Date of Visit:</Form.Label>
                      <Form.Control type="date" />
                    </Form.Group>
                    <Form.Group controlId="location">
  <Form.Label>Location:</Form.Label>
  <Form.Control type="text" placeholder="Enter your location" />
</Form.Group>

                    <Form.Group controlId="firstVisit">
                      <Form.Label>Was this your first visit to the store?</Form.Label>
                      <div className="inline-radio-group">
                        <Form.Check 
                          type="radio" 
                          label="Yes" 
                          name="firstVisit" 
                          value="Yes" 
                          inline
                        />
                        <Form.Check 
                          type="radio" 
                          label="No" 
                          name="firstVisit" 
                          value="No" 
                          inline
                        />
                      </div>
                    </Form.Group>

                    <Form.Group controlId="itemsFound">
                      <Form.Label>Of the item(s) you were shopping for, how many were you able to find?</Form.Label>
                      <div className="inline-radio-group">
                        <Form.Check 
                          type="radio" 
                          label="All" 
                          name="itemsFound" 
                          value="All" 
                          inline
                        />
                        <Form.Check 
                          type="radio" 
                          label="Most" 
                          name="itemsFound" 
                          value="Most" 
                          inline
                        />
                        <Form.Check 
                          type="radio" 
                          label="Some" 
                          name="itemsFound" 
                          value="Some" 
                          inline
                        />
                        <Form.Check 
                          type="radio" 
                          label="None" 
                          name="itemsFound" 
                          value="None" 
                          inline
                        />
                      </div>
                    </Form.Group>
                    
                    <div className="form-group">
                      <label htmlFor="feedbackType">Please choose the best answer for each of the following:</label>

                      <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '190px', marginBottom: '10px' }}>
                        <label style={{ width: '110px', textAlign: 'center' }}>Excellent</label>
                        <label style={{ width: '110px', textAlign: 'center' }}>Good</label>
                        <label style={{ width: '110px', textAlign: 'center', marginRight: '10px' }}>Fair</label>
                        <label style={{ width: '110px', textAlign: 'center', marginRight: '20px' }}>Poor</label>
                      </div>

                      {/* SERVICE */}
                      <label style={{ marginTop: '15px', fontWeight: 'bold' }}>SERVICE</label>
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                        <span style={{ width: '150px' }}>Greeted/Thanked</span>
                        <input type="radio" name="Three" value="Excellent" style={{ marginLeft: '60px' }} />
                        <input type="radio" name="Three" value="Good" style={{ marginLeft: '78px' }} />
                        <input type="radio" name="Three" value="Fair" style={{ marginLeft: '72px' }} />
                        <input type="radio" name="Three" value="Poor" style={{ marginLeft: '70px' }} />
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginTop: '10px' }}>
                        <span style={{ width: '150px' }}>Efficient</span>
                        <input type="radio" name="Four" value="Excellent" style={{ marginLeft: '60px' }} />
                        <input type="radio" name="Four" value="Good" style={{ marginLeft: '78px' }} />
                        <input type="radio" name="Four" value="Fair" style={{ marginLeft: '72px' }} />
                        <input type="radio" name="Four" value="Poor" style={{ marginLeft: '70px' }} />
                      </div>

                      {/* MERCHANDISE */}
                      <label style={{ marginTop: '15px', fontWeight: 'bold' }}>MERCHANDISE</label>
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginTop: '10px' }}>
                        <span style={{ width: '150px' }}>Selection</span>
                        <input type="radio" name="Six" value="Excellent" style={{ marginLeft: '60px' }} />
                        <input type="radio" name="Six" value="Good" style={{ marginLeft: '78px' }} />
                        <input type="radio" name="Six" value="Fair" style={{ marginLeft: '72px' }} />
                        <input type="radio" name="Six" value="Poor" style={{ marginLeft: '70px' }} />
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginTop: '10px' }}>
                        <span style={{ width: '150px' }}>Cleanliness</span>
                        <input type="radio" name="Seven" value="Excellent" style={{ marginLeft: '60px' }} />
                        <input type="radio" name="Seven" value="Good" style={{ marginLeft: '78px' }} />
                        <input type="radio" name="Seven" value="Fair" style={{ marginLeft: '72px' }} />
                        <input type="radio" name="Seven" value="Poor" style={{ marginLeft: '70px' }} />
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginTop: '10px' }}>
                        <span style={{ width: '150px' }}>Ease of Shopping</span>
                        <input type="radio" name="Eight" value="Excellent" style={{ marginLeft: '60px' }} />
                        <input type="radio" name="Eight" value="Good" style={{ marginLeft: '78px' }} />
                        <input type="radio" name="Eight" value="Fair" style={{ marginLeft: '72px' }} />
                        <input type="radio" name="Eight" value="Poor" style={{ marginLeft: '70px' }} />
                      </div>

                      <Form.Group controlId="comments">
                        <Form.Label>Additional Comments:</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                      </Form.Group>

                      <Button variant="primary" type="submit">
                        Submit Feedback
                      </Button>
                    </div>
                  </Form>
                </>
              )}
              {selectedOption === "Business" && (
                <>
                  <h2>Business Inquiry</h2>
                  {/* Display error or success message */}
                  {showError && <Alert variant="danger">Please fill out all the fields before submitting the form.</Alert>}
                  {showSuccess && <Alert variant="success">Form submitted successfully!</Alert>}
                  
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="companyName">
                      <Form.Label>Company Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        placeholder="Enter your company name"
                      />
                    </Form.Group>

                    <Form.Group controlId="contactPerson">
                      <Form.Label>Contact Person:</Form.Label>
                      <Form.Control
                        type="text"
                        name="contactPerson"
                        value={formData.contactPerson}
                        onChange={handleChange}
                        placeholder="Enter your Full name"
                      />
                    </Form.Group>

                    <Form.Group controlId="position">
                      <Form.Label>Position:</Form.Label>
                      <Form.Control
                        type="text"
                        name="position"
                        value={formData.position}
                        onChange={handleChange}
                        placeholder="Enter your job title"
                      />
                    </Form.Group>

                    <Form.Group controlId="streetAddress">
                      <Form.Label>Street Address:</Form.Label>
                      <Form.Control
                        type="text"
                        name="streetAddress"
                        value={formData.streetAddress}
                        onChange={handleChange}
                        placeholder="Enter your street address"
                      />
                    </Form.Group>

                    <Form.Group controlId="city">
                      <Form.Label>City:</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        placeholder="City"
                      />
                    </Form.Group>

                    <Form.Group controlId="state">
                      <Form.Label>State:</Form.Label>
                      <Form.Control
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        placeholder="State/Province"
                      />
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>Email:</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="you@example.com"
                      />
                    </Form.Group>

                    <Form.Group controlId="phoneNumber">
                      <Form.Label>Phone Number:</Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        placeholder="(555) 555-5555"
                      />
                    </Form.Group>

                    <Form.Group controlId="enquiry">
                      <Form.Label>Inquiry Details:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="enquiry"
                        value={formData.enquiry}
                        onChange={handleChange}
                        placeholder="Briefly describe your inquiry"
                      />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      Submit Inquiry
                    </Button>
                  </Form>
                </>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default ContactUs;
