import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import Content from './components/Content';
import AboutUs from './components/AboutUs';
import Values from './components/Values';
import Gallery from './components/Gallery';
import Partners from './components/Partners';
import Careers from './components/Careers';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Benefits from './components/Benefits';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://use.typekit.net/lpj0ylu.css'; // Replace with your actual Adobe kit URL
    document.head.appendChild(link);
  }, []); 
  return (
    <div className="App">
      <div>  
    <Router>
    <Navbar />
      <Routes>
        <Route path = "/" Component={Content} /> 
        <Route path="/aboutUs" Component={AboutUs}/>
        <Route path="/values" Component={Values}/>
        <Route path="/gallery" Component={Gallery}/>
        <Route path="/partners" Component={Partners}/>
        <Route path="/careers" Component={Careers}/>
        <Route path="/benefits" Component={Benefits}/>
        <Route path="/contactUs" Component={ContactUs}/>
        </Routes>
        </Router>
        </div>
        <Footer />
    </div>
  );
}

export default App;