import React from 'react';
import '../styles.css'; // Adjust path as needed

import retail1 from '../assets/Gallery/WebSized-54.jpg';
import retail2 from '../assets/Gallery/WebSized-90.jpg';
import retail3 from '../assets/Gallery/WebSized-52.jpg';
import retail4 from '../assets/Gallery/WebSized-51.jpg';
import dining1 from '../assets/Gallery/Missoula-Pic-5.jpg';
import dining2 from '../assets/Gallery/Missoula-Pic-6.jpg';
import dining3 from '../assets/Gallery/Chicago_Pic-3.jpg';
import dining4 from '../assets/Gallery/Chicago_Pic-4.jpg';
import customer1 from '../assets/Gallery/Missoula-Pic-9.jpg';
import customer2 from '../assets/Gallery/Missoula-Pic-10.jpg';
import customer3 from '../assets/Gallery/Pic-2.jpg';
import customer4 from '../assets/Gallery/Newark-Pic-2.jpg';
import special1 from '../assets/Gallery/Pic-14.JPG';
import special2 from '../assets/Gallery/BDL-pic-1.jpg';
import special3 from '../assets/Gallery/Chicago_Polar_Express_Event-3.jpg';
import special4 from '../assets/Gallery/BDL-pic-5.jpg';

function Gallery() {
  return (
    <div className="gallery-page">
      <h2 className="gallery-title">OUR GALLERY</h2>

      {/* Retail Outlets Section */}
      <div className="gallery-section">
        <h3 className="section-title">RETAIL</h3>
        <div className="gallery-grid">
          <img src={retail1} alt="Retail Outlet 1" />
          <img src={retail2} alt="Retail Outlet 2" />
          <img src={retail3} alt="Retail Outlet 3" />
          <img src={retail4} alt="Retail Outlet 4" />
        </div>
      </div>

      {/* Dining Experiences Section */}
      <div className="gallery-section">
        <h3 className="section-title">DINING EXPERIENCES</h3>
        <div className="gallery-grid">
          <img src={dining1} alt="Dining Experience 1" />
          <img src={dining2} alt="Dining Experience 2" />
          <img src={dining3} alt="Dining Experience 3" />
          <img src={dining4} alt="Dining Experience 4" />
        </div>
      </div>
      <div className="gallery-section">
        <h3 className="section-title">CUSTOMER SERVICE AND INTERACTION</h3>
        <div className="gallery-grid">
          <img src={customer1} alt="CUSTOMER SERVICE 1" />
          <img src={customer2} alt="CUSTOMER SERVICE 2" />
          <img src={customer3} alt="CUSTOMER SERVICE 3" />
          <img src={customer4} alt="CUSTOMER SERVICE 4" />
        </div>
      </div>

      <div className="gallery-section">
        <h3 className="section-title">SPECIAL EVENTS</h3>
        <div className="gallery-grid">
          <img src={special1} alt="SPECIAL EVENTS 1" />
          <img src={special2} alt="SPECIAL EVENTS 2" />
          <img src={special3} alt="SPECIAL EVENTS 3" />
          <img src={special4} alt="SPECIAL EVENTS 4" />
        </div>
      </div>

    </div>
  );
}

export default Gallery;
