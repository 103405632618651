import React, { useState, useEffect } from 'react';
import '../styles.css';
import image1 from '../assets/Landing_Page/Image_6.jpg';
import image2 from '../assets/Landing_Page/Image_2.jpg';
import image3 from '../assets/Landing_Page/Image_3.jpg';
import image4 from '../assets/Landing_Page/Image_4.png';
import logo from '../assets/Faber-Logo.png';
import backgroundImage from '../assets/Landing_Page/Image_5.jpg';
import location1 from '../assets/airports&Trains/Location_1.jpg';
import location2 from '../assets/airports&Trains/Location_2.jpg';
import location3 from '../assets/airports&Trains/Location_3.jpg';
import location4 from '../assets/airports&Trains/Location_4.jpg';
import brand1 from '../assets/Landing_Page/Brand_13.jpg';
import brand2 from '../assets/Landing_Page/Brand_2.png';
import brand3 from '../assets/Landing_Page/Brand_3.jpeg';
import brand4 from '../assets/Landing_Page/Brand_4.png';
import brand5 from '../assets/Landing_Page/Brand_5.png';
import brand6 from '../assets/Landing_Page/Brand_6.jpg';
import brand8 from '../assets/Landing_Page/Brand_8.jpg';
import brand9 from '../assets/Landing_Page/Brand_9.jpg';
import brand10 from '../assets/Landing_Page/Brand_10.jpg';
import brand11 from '../assets/Landing_Page/Brand_11.jpg';
import brand12 from '../assets/Landing_Page/Brand_12.jpg';
import brand14 from '../assets/Landing_Page/Brand_14.jpg';
import brand15 from '../assets/Landing_Page/Brand_15.jpg';
import brand16 from '../assets/Landing_Page/Brand_16.jpg';
import brand17 from '../assets/Landing_Page/Brand_17.jpg';
import brand18 from '../assets/Landing_Page/Brand_18.jpg';
import brand19 from '../assets/Landing_Page/Brand_19.jpg';
import brand20 from '../assets/Landing_Page/Brand_20.jpg';
import mainImage from '../assets/Landing_Page/Whatwedo.jpg';
import sidebarImage1 from '../assets/Landing_Page/Whatwedo_1.jpg';
import sidebarImage2 from '../assets/Landing_Page/Whatwedo_2.jpeg';
import sidebarImage3 from '../assets/Landing_Page/Whatwedo_3.jpg';
import LocationsHomePage from './LocationsHomePage';

const images = [
  { src: image1},
  { src: image2},
  { src: image3 },
  { src: image4 },
];

function Content() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 3000);

    // Disable right-click and drag on images
    const handleContextMenu = (e) => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
      }
    };

    const disableDrag = (e) => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('dragstart', disableDrag);

    return () => {
      clearInterval(interval);
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('dragstart', disableDrag);
    };
  }, [currentIndex]);

  return (
    <div>
      <div className="carousel">
        <img
          src={images[currentIndex].src}
          alt="Carousel"
          className="carousel-image"
        />
        <div className="white-overlay"></div>
        <div
          className={`text-overlay`}
          style={{
            fontWeight: 900,
            fontSize: '2.2em',
            color: '#cc0000',
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.9)',
          }}
        >
          <h1 className="heritage-heading">TRAVELERS' FIRST CHOICE</h1>
          <h2 className="heritage-subheading" style={{color: "black"}}>
            175 <span className="heritage-plus">+</span> YEARS OF HERITAGE
          </h2>
          <p className="heritage-paragraph" style={{color: "#B22222", fontWeight: "normal" }}>
            It all began in 1848 with the original American <br /> Indian Cigar Store located in
            hotels, train <br/>terminals, and office buildings. These stores<br/> were the country’s
            first news/convenience stores.
          </p>
        </div>
        <button className="next-button" onClick={nextImage}></button>
      </div>
      <div className="highlight-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="yellow-overlay"></div>
        <div className="highlight-content">
          <h1>TRAVELERS' FIRST CHOICE</h1>
          <p>
            We have been reaching the hearts of travelers for 175 years. We <br/>
            strive to provide our customers with products and services of <br/>
            excellent quality and value that will enable us to achieve the highest<br/>
            level of customer satisfaction, growth, and stability.
          </p>
          <p className="highlight-subtext" style={{ color: "darkslategray" }}>175+ YEARS OF HERITAGE</p>
        </div>
        <div className="highlight-logo">
          <img src={logo} alt="Faber Logo" />
        </div>
      </div>

      <div className="locations-brands-section">
        <div className="locations-section">
          <h2>SOME OF OUR LOCATIONS</h2>
          <LocationsHomePage />
        </div>
        <div class="custom-divider"></div>
        <div className="brands-section">
          <h2>SOME OF OUR BRANDS</h2>
          <div className="brands-gallery">
            <img src={brand1} alt="Dunkin Donuts" />
            <img src={brand2} alt="Chandeleur" />
            <img src={brand3} alt="Cold Smoke" />
            <img src={brand4} alt="Jimmy Johns" />
            <img src={brand5} alt="Sonic" />
            <img src={brand6} alt="Starbucks" />
            <img src={brand8} alt="Baskin Robbins" />
            <img src={brand9} alt="Baskin Robbins" />
            <img src={brand10} alt="Baskin Robbins" />
            <img src={brand11} alt="Baskin Robbins" />
            <img src={brand12} alt="Baskin Robbins" />
            <img src={brand14} alt="Baskin Robbins" />
            <img src={brand15} alt="Baskin Robbins" />
            <img src={brand16} alt="Baskin Robbins" />
            <img src={brand17} alt="Baskin Robbins" />
            <img src={brand18} alt="Baskin Robbins" />
            <img src={brand19} alt="Baskin Robbins" />
            <img src={brand20} alt="Baskin Robbins" />
          </div>
        </div>
      </div>

      <div className="what-we-do-section">
        <div className="main-image-container">
          <img src={mainImage} alt="Main" className="main-image" />
          <div className="main-overlay">
            <h1 className="main-title">WHAT WE DO</h1>
          </div>
        </div>
        <div className="text-content">
          <p>
            Faber satisfies travelers with food, beverages, and retail serving millions of customers in airports and train terminals. 
            From travel essentials to unique specialty boutiques, Faber has the knowledge and skill to develop the right concession program for you.
          </p>
          <p>
            Faber’s conservative growth philosophy is to select the right locations, ensure profitability, and fulfill the needs of the traveler.
            Dedication to our four main business fundamentals—Experience & Stability, Customer Focus, Performance & Creativity, and Flexibility—helps us turn ideas into reality.
          </p>
        </div>

        <div className="sidebar-container">
          <div className="sidebar">
            <div className="sidebar-item">
              <img src={sidebarImage1} alt="Customer Service" />
              <div className="sidebar-caption">We are committed to making your journey enjoyable and stress-free with outstanding customer service and convenience.</div>
            </div>
            <div className="sidebar-item">
              <img src={sidebarImage2} alt="Merchandise" />
              <div className="sidebar-caption">Our stores offer a vast selection of merchandise, making it easy for you to shop while traveling.</div>
            </div>
            <div className="sidebar-item">
              <img src={sidebarImage3} alt="POS Solution" />
              <div className="sidebar-caption">At Faber, we use a cutting-edge POS solution that enhances our efficiency and ensures seamless customer service.</div>
            </div>
          </div>
        </div>
      </div>
    </div>   
  );
}

export default Content;
