import React from 'react';
import '../styles.css'; // Adjust path as needed

// Import image (replace path with actual file location)
import partnersImage from '../assets/Partners/Gulfport.jpeg';

function Partners() {
  return (
    <div className="partners-section">
      {/* Image Section */}
      <div className="partners-image-container">
        <img src={partnersImage} alt="Partners" />
        <h2 className="partners-title">PARTNERS</h2>
      </div>

      {/* Content Section */}
      <div className="partners-content">
        <h3>PARTNER WITH US</h3>
        <p>Faber offers unparalleled expertise in store operations, merchandising, marketing, and exceptional customer service. As a leading airport concessionaire, we specialize in transforming airports into premier retail and dining destinations, ensuring a world-class experience for travelers. We also have an extensive brand portfolio in both our specialty retail and dining division.</p>

        <h3>WHY PARTNER WITH US?</h3>
        <p>Suppliers looking for exposure in our high-traffic airport environments benefit from:</p>
        <ul>
          <li>Unique and powerful customer service programs</li>
          <li>Access to an international customer base</li>
          <li>Highly-trained and engaged associates</li>
          <li>Brand partners can expect seamless execution of their brands</li>
          <li>Industry-leading quality in store and restaurant design, construction, and operations</li>
          <li>Exciting new marketing opportunities</li>
        </ul>

        <h3>ACDBE PARTNERSHIPS</h3>
        <p>Faber has a solid history of not only being an equal opportunity employer, but also fostering and mentoring relationships with small and minority-certified businesses to help other companies grow and succeed. Through this, Faber has much experience partnering with ACDBE firms, including joint venture agreements, sub-tenancy agreements, and supplier relationships with ACDBE-certified companies in its concessions locations at airports.</p>
      </div>
    </div>
  );
}

export default Partners;
