import React from 'react';
import { useNavigate } from 'react-router-dom';
import Employees from "../assets/Careers/Faber-Employees.jpg";
//import Employees from "../assets/Employees.png";
// import employee_video_first from "../assets/employee_video_first.png";
import '../styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faDribbble, faPinterest } from '@fortawesome/free-brands-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import DiversityEmployee from '../assets/DiveristyEmployee.jpg';
import DiversityEmployee from '../assets/Careers/BDL-pic-2.jpg';
import Upliftment from '../assets/Careers/pic-8.jpg';
// import Leadership from '../assets/Leadership.png';
import Leadership from '../assets/Careers/H2.jpg';
import HealthInsurance from '../assets/Careers/HealthInsurance.png';
import RetirementPlans from '../assets/Careers/RetirementPlans.png';
import LifeInsurance from '../assets/Careers/LifeInsurance.png';
import Vacationlogo from '../assets/Careers/Vacationlogo.png';
import Employeediscounts from '../assets/Careers/EmployeeDiscounts.png';
import Weeklypay from '../assets/Careers/Weeklypay.png';
import Activity from '../assets/Careers/pic-6.jpg';
import Passionate from '../assets/Careers/commitment.jpg';
import Centric from '../assets/Careers/BDL-pic-1.jpg';
import Trenton from '../assets/Careers/Trenton.jpg';
import CareersHomePage from "./CareersHomePage";
const Careers = () => {
  const navigate = useNavigate();

  const handleNavigate = (section) => {
    // First, navigate to the URL with the section anchor
    navigate(`/benefits#${section}`);
  
    // Delay the scroll to allow the page to navigate and load the content
    setTimeout(() => {
      const element = document.getElementById(section);
      if (element) {
        const offset = element.offsetTop - 400; // Adjust -100 to whatever offset you need
        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }, 300); // Increase delay to 300ms to ensure page navigation completes
  };
  const styles = {
    // Customize styles based on screen size
    '@media only screen and (max-width: 600px)': {
      heading: {
        fontSize: '1.5em',
        textAlign: 'center',
      },
    },
    '@media only screen and (min-width: 601px) and (max-width: 1024px)': {
      container: {
        padding: '15px',
      },
    },
    '@media only screen and (min-width: 1025px)': {
      container: {
        maxWidth: '800px',
        margin: '0 auto',
      },
    },
  };
    let message = `Our team is dedicated to providing exceptional services and experiences to travelers, ensuring a seamless and enjoyable time at the airport`;
    return (

      <div style={styles}>
    <div className='All-Careers-page'>
    <div className="careers-header-overlay">
      <img src={Employees} alt="Image" className="careers-header-image " />
      <div className="careers-overlay-text">
        <h1>WHY JOIN FABER?</h1>
        <p>Soar higher with us, where every journey begins <br/>with the strength of our united team.</p>
      </div>
    </div>
    {/* <div className="col-md-12 text-center">
                <h2 style={{ color: '#285185', fontWeight: 'bold', fontSize: '2.5rem', marginTop: '60px' }} className="OurBenefits"><span>Why</span> Join Faber?</h2>
                <p style={{  fontSize: '1.2rem' }} className="section-subtitle">Soar higher with us, where every journey begins with the strength of our united team.</p>
              </div> */}
        <div className='containercareers'>
          <div className='boxcareers'>
            <div className="content-careers">
              <img src={DiversityEmployee} alt='Image' className='gallery-image'/>
              <h2>Diversity</h2>
              <p>At Faber, we believe that diversity is a core value that is integral to our success.
                We are committed to creating a workplace and a community that is inclusive and welcoming to everyone, and we believe that by embracing diversity, we can
                build stronger relationships, foster greater understanding, and create positive change in the world.</p>
            </div>
          </div>
          <div className='boxcareers'>
            <div className="content-careers">
              <img src={Trenton} alt='Image' className='gallery-image'/>
              <h2>Team Collaboration</h2>
              <p>At Faber, team collaboration is the heartbeat of our operations.
                Each department from retail to customer service, seamlessly integrates to ensure a unified and efficient experience.
                Regular cross-functional meetings and open communication channels foster collaboration,making sure everyone's insights contribute to the success of our endeavors.</p>
            </div>
          </div>
          <div className='boxcareers'>
            <div className="content-careers">
              <img src={Upliftment} alt='Image' className='gallery-image'/>
              <h2>Career Upliftment</h2>
              <p>Join a company committed to nurturing talent, providing ample opportunities for professional development and advancement. From mentorship programs to skill-building opportunities, we invest in your development ensuring that your career takes flight alongside our success.</p>
            </div>
          </div>
        </div>
        <div className='containercareers'>
          <div className="boxcareers">
            <div className="content-careers">
              <img src={Passionate} alt='Image' className='gallery-image'/>
              <h2>Passionate Team</h2>
              <p>Join a team of dedicated professionals who are passionate about what they do, creating a motivating and inspiring work environment.</p>
            </div>
          </div>
          <div className='boxcareers'>
            <div className="content-careers">
              <img src={Centric} alt='Image' className='gallery-image'/>
              <h2>Customer-Centric Focus</h2>
              <p>Contribute to delivering exceptional customer experiences, shaping the future of airport retail and service with a focus on customer satisfaction.</p>
            </div>
          </div>
          <div className='boxcareers'>
            <div className="content-careers">
              <img src={Activity} alt='Image' className='gallery-image'/>
              <h2>Exciting Environment</h2>
              <p>Join us for a dynamic workplace situated in the heart of airport activity, providing a unique and vibrant professional experience.</p>
            </div>
          </div>
        </div>

        <section className="section-white">
        <h2 style={{ color: '#285185', fontWeight: 'bold', fontSize: '2.5rem' }} className="OurBenefits"><span>Our</span> Benefits</h2>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                
                <p className="section-subtitle">{message}</p>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="team-item">
                  <img src={HealthInsurance} className="team-img" alt="Health Insurance" onClick={ () => handleNavigate('HealthInsurance')}/>                  
                  <h3>Health Insurance</h3>            
                  <div className="team-info"><p>Health Insurance</p></div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="team-item">
                  <img src={RetirementPlans} className="team-img" alt="Retirement Plans"  onClick={ () => handleNavigate('RetirementPlans')}/>
                  <h3>Retirement Savings</h3>
                  <div className="team-info"><p>Retirement Savings</p></div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="team-item">
                  <img src={LifeInsurance} className="team-img" alt="Life Insurance" onClick={ () => handleNavigate('LifeInsurance')} />
                  <h3>Life Insurance</h3>
                  <div className="team-info"><p>Life Insurance</p></div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="team-item">
                  <img src={Vacationlogo} className="team-img" alt="Paid vacation and holiday" onClick={ () => handleNavigate('Vacation')} />
                  <h3>Paid and Vacation Leave</h3>
                  <div className="team-info"><p>Paid and Vacation Leave</p></div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="team-item">
                  <img src={Employeediscounts} className="team-img" alt="Employee Perks" onClick={ () => handleNavigate('Discounts')} />                  
                  <h3>Employee Discounts</h3>            
                  <div className="team-info"><p>Employee Discounts</p></div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="team-item">
                  <img src={Weeklypay} className="team-img" alt="pic"  onClick={ () => handleNavigate('Weekly')}/>                  
                  <h3>Weekly pay</h3>            
                  <div className="team-info"><p>Weekly pay</p></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <h2 style={{ color: '#285185', fontWeight: 'bold', fontSize: '2.5rem' }} className="PhotoGallery"><span>Photo</span> Gallery</h2>
          <CareersHomePage />
        </div>
      </div>
      </div>
    );
};

export default Careers;
