import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import '../styles.css';
import employeebenefits from '../assets/Benefits/employeebenefits.jpg';
import exercise from '../assets/Benefits/healthInsurance.png';
import retirement from '../assets/Benefits/retirementPlans.jpg';
import vacation from '../assets/Benefits/VacationNew.jpg';
import weekly from '../assets/Benefits/WeeklyPayNew.jpg';
import LifeInsurance from '../assets/Benefits/LifeInsuranceFamily.jpg';
import perks from '../assets/Benefits/perks.jpg';
const Benefits = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash){
            const element = document.getElementById(location.hash.substring(1));
            if (element){
                element.scrollIntoView({behavior: 'smooth'});
            
        }
        }
    }, [location]);
    const styles = {
        '@media only screen and (max-width: 600px)': {
            heading: {
                fontSize: '1.5em',
                textAlign: 'center',
            },
        },
        '@media only screen and (min-width: 601px) and (max-width: 1024px)': {
            container: {
                padding: '15px',
            },
        },
        '@media only screen and (min-width: 1025px)': {
            container: {
                maxWidth: '800px',
                margin: '0 auto',
            },
        },
        benefitImage: {
            maxWidth: '100%',
            height: 'auto',
        },
        valuesContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            marginBottom: '2rem',
        },
        valuesRowContent: {
            flex: '1 1 50%',
            padding: '1rem',
        },
        imageContainer: {
            flex: '1 1 50%',
            padding: '1rem',
        },
    };

    return (
        <div style={styles.container}>
            <div className="about-us-container">
      <div className="hero-section">
        <img src={employeebenefits} alt="Our Benefits" className="hero-image" />
        <div className="hero-overlay">
          <h1>Our Benefits</h1>
        </div>
      </div>
      </div>
        
            <div style={styles.valuesContainer} className="container my-5">
                <div style={styles.imageContainer}>
                    <img src={exercise} alt="Health Insurance" style={styles.benefitImage} />
                </div>
                <div style={styles.valuesRowContent}>
                    <section id='HealthInsurance'>
                    <h2 className="display-8 fs-md-5 fs-lg-4">Health Insurance</h2>
                    <p className="custom-font-size-values-row">
                        Faber is well aware of the high cost of receiving medical attention and treatment. Because the
                        Company wants its associates and their dependents to be able to obtain the medical treatment they
                        need and deserve, Faber permits all regular full-time associates and regular part-time associates
                        with benefits to participate in its health insurance plan.
                    </p>
                    <ul className="custom-font-size-values-row">
                        <li className="custom-font-size-values-row">Medical Insurance</li>
                        <li className="custom-font-size-values-row">Prescription Coverage</li>
                        <li className="custom-font-size-values-row">Vision Coverage</li>
                        <li className="custom-font-size-values-row">Dental Insurance</li>
                    </ul>
                    </section>
                </div>
            </div>
            <div style={styles.valuesContainer} className="container my-3">
                <div style={styles.imageContainer}>
                    <img src={retirement} alt="Retirement Plans" style={styles.benefitImage} />
                </div>
                <div style={styles.valuesRowContent}>
                    <section id='RetirementPlans'>
                    <h2 className="display-8 fs-md-5 fs-lg-4">Retirement Plans</h2>
                    <p className="custom-font-size-values-row">
                        To help provide financial security for our associates during their retirement years, Faber maintains
                        a 401(k) Savings and Retirement Plan for eligible associates. All associates who are over twenty
                        one years of age and have completed 6 months of continuous service with the company are eligible
                        to participate as per the 401(k) plan documents.
                    </p>
                    </section>
                </div>
            </div>
            <div style={styles.valuesContainer} className="container my-3">
                <div style={styles.imageContainer}>
                    <img src={LifeInsurance} alt="Life Insurance" style={styles.benefitImage} />
                </div>
                <div style={styles.valuesRowContent}>
                    <section id='LifeInsurance'>
                    <h2 className="display-8 fs-md-5 fs-lg-4">Life Insurance</h2>
                    <p className="custom-font-size-values-row">
                    Our life insurance benefit provides peace of mind by ensuring financial protection for employees' families. 
                    This valuable coverage helps safeguard loved ones in times of need, offering security and support when it matters most.
                    </p>
                    </section>
                </div>
            </div>
            <div style={styles.valuesContainer} className="container my-5">
                <div style={styles.imageContainer}>
                    <img src={vacation} alt="image" style={styles.benefitImage} />
                </div>
                <div style={styles.valuesRowContent}>
                    <section id='Vacation'>
                    <h2 className="display-8 fs-md-5 fs-lg-4">Paid vacation and holidays</h2>
                    <p className="custom-font-size-values-row">
                        We prioritize work-life balance and value our employees' well-being. Our generous vacation and paid holiday policy ensures that our team can recharge, spend quality time with loved ones, and return to work refreshed and motivated.
                    </p>
                    </section>
                </div>
            </div>
            <div style={styles.valuesContainer} className="container my-5">
                <div style={styles.imageContainer}>
                    <img src={perks} alt="image" style={styles.benefitImage} />
                </div>
                <div style={styles.valuesRowContent}>
                    <section id='Discounts'>
                    <h2 className="display-8 fs-md-5 fs-lg-4">Employee Perks</h2>
                    <p className="custom-font-size-values-row">
                        All associates are eligible to receive a discount of 25% on Faber’s merchandise with the following
                        exceptions: books, tobacco products, newspapers, phone cards, magazines, Legos, stamps,
                        alcoholic beverages and lottery tickets.
                    </p>
                    </section>
                </div>
            </div>
            <div style={styles.valuesContainer} className="container my-5">
                <div style={styles.imageContainer}>
                    <img src={weekly} alt="image" style={styles.benefitImage} />
                </div>
                <div style={styles.valuesRowContent}>
                    <section id='Weekly'>
                    <h2 className="display-8 fs-md-5 fs-lg-4">Weekly Pay</h2>
                    <p className="custom-font-size-values-row">
                    We care about our employees' financial security, which is why we offer weekly pay. 
                    This benefit provides a steady income, giving our team peace of mind and the ability
                     to manage their finances more effectively.
                    </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Benefits;
